.components_ui_slider {
  width: 100%;
  .slider_input {
    border: none;
    font-size: 15px;
    margin: 0;
    font-family: como;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .slider_label {
    padding-top: 3px;
    margin-right: 15px;
    width: 90px;
  }
  .slider_root {
    color: #5B78C2 !important;
  }
}
