.containers_login_reset {
    display: flex;
    justify-content: center;
    .login_container_reset {
      display: flex;
      margin-top: 50px;
      margin-bottom: 70px;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      .login_Card_reset {
        width: 45%;
        display: flex;
        justify-content: flex-start;
        padding-top: 10px !important;
        position: relative;
        margin-top: 80px;
        .forget_password_container_reset {
          margin-top: 51px;
          a {
            font-size: 20px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #ed1e79;
          }
        }
      }
  
      .error_message_reset {
        height: 3px;
        font-size: 14px;
        margin: 5px;
        display: flex;
        margin-top: 10px;
        justify-content: flex-start;
        align-items: center;
        color: red;
      }
      .ignoreTransform_reset {
        transform: none;
      }
      .input_wrapper_reset {
        width: 100%;
        margin: 20px 0px 31px 0px;
      }
  
      .submit_button_reset {
        width: 373px !important;
        height: 57px !important;
      }
    }
  }
  