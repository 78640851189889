@import './variables';
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  font-family: Gangster Grotesk;
  color: #434343;
}

* {
  flex: 0 0 auto;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

button {
  border: none;
  background: transparent;
  &:focus {
    outline: none;
  }
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.position_relative {
  position: relative;
}

.fill {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.absolute_fill {
  position: absolute;
  @extend .fill;
}

.fixed_fill {
  position: fixed;
  @extend .fill;
}

.display_none {
  display: none;
}

button {
  border: none;
  cursor: pointer;
}

button,
input {
  &:focus {
    outline: none;
  }
}

.cursor_pointer {
  cursor: pointer;
}

@for $i from 1 through 100 {
  .width_#{$i} {
    width: $i * 1%;
  }

  .height_#{$i} {
    height: $i * 1%;
  }

  .width_vw_#{$i} {
    width: $i * 1vw;
  }

  .height_vh_#{$i} {
    height: $i * 1vh;
  }
}

@for $i from 1 through 50 {
  .padding_#{$i} {
    padding: $i * 1px;
  }

  .padding_h_#{$i} {
    padding-left: $i * 1px;
    padding-right: $i * 1px;
  }

  .padding_v_#{$i} {
    padding-top: $i * 1px;
    padding-bottom: $i * 1px;
  }

  .margin_#{$i} {
    margin: $i * 1px;
  }

  .margin_h_#{$i} {
    margin-left: $i * 1px;
    margin-right: $i * 1px;
  }

  .margin_v_#{$i} {
    margin-top: $i * 1px;
    margin-bottom: $i * 1px;
  }
  .raduis_#{$i} {
    border-radius: $i * 1px;
  }
}

@for $i from 0 through 10 {
  .flex_#{$i} {
    flex: $i $i 0%;
    min-height: 0px;
  }
}

.page {
  @extend .flex_1;
  @extend .flex_column;
  margin: 0 auto;
  width: 100%;
  @media (max-width: 1181px) {
    padding: 0 20px;
  }
}

.with_layout_page {
  overflow: auto;
}

.text_nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.title_component {
  font-family: Barlow;
  font-size: 33px;
  font-weight: 600;
  font-stretch: condensed;
  letter-spacing: 1.26px;
  color: #ed1e79;
}
.sub_title_component {
  font-size: 20px;
  font-weight: 300;
  color: #ed1e79;
}
.text_menu_component {
  font-family: Gangster Grotesk;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  color: #434343;
}
.text_classic_component {
  font-size: 20px;
  font-weight: 300;
  color: #4d4d4d;
}
.text_tab_bar_component {
  font-size: 17px;
  font-weight: 300;
  color: #4d4d4d;
}
.text_secondaire_component {
  font-family: Gangster Grotesk;
  font-size: 14px;
  line-height: 17px;
  color: #434343;
}
.text_small_component {
  font-size: 12px;
  font-weight: bold;
  color: #4d4d4d;
}
.text_pink_component {
  font-family: Gangster Grotesk;
  font-size: 14px;
  color: #ff5c97;
}
.number_large {
  font-size: 96px;
  font-weight: bold;
}
.number_medium {
  font-size: 30px;
  font-weight: bold;
}
.color_yellow {
  color: #f7931e;
}

// <------------- generic styles for material ui componentt ------------>
