$icon_size: 38px;

.components_ui_icon {
  width: 54px;
  height: 54px;
  border-radius: 4px;
  background-color: #DDF7FF;

  .icon_container {
    height: $icon_size;
    width: $icon_size;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }
}

.components_ui_icon_selected {
  background-color: #5B78C2;
}
