.components_inputs_label {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 18px;
  letter-spacing: normal;
  color: #0C3D9C;
  .components_inputs_required_field {
    color: #0C3D9C;
  }
}
