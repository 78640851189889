@font-face {
  font-family: Gangster Grotesk;
  src: url('./assets/font/Gangster Grotesk Regular 400.otf') format('opentype');
}

@font-face {
  font-family: Rakkas;
  src: url('./assets/font/Rakkas-Regular.ttf');
}

a {
  color: inherit;
  font-family: Gangster Grotesk;
}
