.container_award_badge {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .award_badge_form {
    width: 80%;
    padding: 20px;
    // box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    border-radius: 0px 0px 5px 5px;
    .text_Field_award {
      width: 100%;
      margin: 20px 0;
    }
    .button_Container {
      display: flex;
      justify-content: flex-end;
      .button {
        background-color: #0C3D9C;
        padding: 20px 30px;
        height: 50px;
      }
    }

    .title_header {
      color: #0C3D9C;
      font-weight: bolder;
      padding-left: 10px;
      display: flex;
      align-items: center;
    }
    .description_header {
      color: grey;
      padding-left: 10px;
    }
  }
}
.award_info {
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.button_close {
  background-color: #fc5e2d !important;
  padding: 0 15px !important;
}
