.components_modals_model_select {
  flex-wrap: wrap;
  overflow: auto;
  display: flex;
  justify-content: center;
  .model {
    width: 219px;
    height: 219px;
    cursor: pointer;
    border: 1px solid #5b78c2;
    border-radius: 10px;
    margin: 18px;
    padding: 5px;
    img {
      width: 200px;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .selected_model {
    border: 4px solid #5b78c2;
    background-color: #ddf7ff;
  }
}
