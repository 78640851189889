.components_badge_create_badge_icons_carousel {
  display: flex;
  flex-wrap: wrap;
  .badge_model_icons {
    margin: 1.5px;
    border-radius: 50%;
    width: 75px;
    height: 75px;
  }
}
