.components_inputs_select {
  width: 100%;
  .root_select {
    font-size: 20px;
    &:hover {
      background-color: red;
    }
  }
  .select_option_icon {
    margin-left: 24px;
  }
  .select_input {
    width: 100%;
    border-radius: 4px;
    color: #2d2a26 !important;
    background-color: #ddf7ff;
    border: 1px solid #5b78c2;
    font-size: 20px;
    fieldset {
      display: none;
    }
    .select_input_menu {
      font-size: 18px;
      line-height: 1.33;
      box-sizing: border-box;
      height: var(--input-height) !important;
      padding: 12px 16px;
      font-size: 20px;
      display: flex;
      align-items: center;
    }
    .select_input_icon {
      color: #000;
      font-size: 28px;
      top: calc(50% - 14px) !important;
    }
  }
  .select_background_disabled {
    background: rgba(0, 0, 0, 0.12) !important;
    ~ svg {
      color: rgba(0, 0, 0, 0.3) !important;
    }
    ~ fieldset {
      border: none !important;
    }
  }

  .image_container {
    position: absolute;
    right: 15px;
    pointer-events: none;
  }
  .components_inputs_select_with_error {
    border-color: #d42061 !important;
  }

  .option_none {
    display: none;
  }
  .select_image {
    margin-left: 24px;
  }
}
.select_menu_item_container {
  font-size: 20px !important;
}
