.checkbox_container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .label_checkbox {
    font-family: Gangster Grotesk;
    font-size: 20px;
    line-height: 30px;
    color: #434343;
    padding-left: 34px;
    cursor: pointer;
    &:before,
    &:after {
      font-weight: bold;
    }
  }
  input[type='checkbox'] {
    position: relative;
    height: 18px;
    width: 18px;
    margin: 0;
    padding: 0;
    &:before,
    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 3px;
      background-color: #ddf7ff;
      cursor: pointer;
    }
    &:before {
      border: 2px solid #5b78c2;
      content: '';
    }
    &:checked:after {
      background: url(../../../assets/svg/checkedBlue.svg);
      background-size: calc(100% - 4px);
      background-repeat: no-repeat;
      background-position: center;

      cursor: pointer;
      content: '';
    }
  }
}
