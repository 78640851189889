$width: 40px;
$height: 40px;

$bounce_height: 80px;
$color: #ede7f6;

.components_ui_loader {
  background-color: rgba($color: #212121, $alpha: 0.5);
  z-index: 9999;
  .loader_text {
    color: $color;
    margin-left: 15px;
    font-size: 20px;
    text-align: center;
    font-weight: bolder;
    line-height: 1.33;
  }

  .loading {
    display: flex;
    align-items: flex-end;
  }

  .bounceball {
    position: relative;
    display: inline-block;
    height: $bounce_height + 15px;
    width: $width;
    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      width: $width;
      height: $height;
      border-radius: 50%;
      background-color: $color;
      transform-origin: 50%;
      animation: bounce 500ms alternate infinite ease;
    }
  }

  @keyframes bounce {
    0% {
      top: $bounce_height;
      height: 5px;
      border-radius: 60px 60px 20px 20px;
      transform: scaleX(2);
    }
    35% {
      height: $height;
      border-radius: 50%;
      transform: scaleX(1);
    }
    100% {
      top: 0;
    }
  }
}
