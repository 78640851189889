.components_inputs_date_picker {
  width: 100%;
  display: flex;
  position: relative;
  .date_picker_input_container {
    display: flex;
    height: 45px;
    width: 100%;
    border-radius: 3px;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    transition: all 225ms linear;
    position: relative;
    align-items: center;
    padding: 10px;
    border: 1px solid #e0e0e0;
    color: #000;
    font-size: 16px;
    padding-left: 20px;
  }

  .date_picker_input_container_error {
    border-color: #f14e7f;
  }

  .date_picker_hidden_input {
    display: none;
  }

  .date_picker_input_part {
    height: 100%;
    padding: 0;
    margin: 0;
    font-size: inherit;
    border: none;
    font-family: inherit;
    height: 100%;
  }

  .day_part {
    width: 22px;
  }

  .month_part {
    width: 30px;
    &:not(:placeholder-shown) {
      width: 22px;
    }
  }

  .year_part {
    flex: 1 1 0%;
  }

  .date_picker_input {
    height: 45px;
    width: 100%;
    border-radius: 3px;
    background-color: #fff;

    transition: all 225ms linear;
    transition: border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, border-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &:focus {
      border-width: 2px;
      border-color: #3f51b5;
    }
    @keyframes autofill {
      to {
        color: #000;
        background-color: #fff;
      }
    }

    &:-webkit-autofill {
      animation-name: autofill;
      animation-fill-mode: both;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
    &::-webkit-clear-button {
      display: none; /* Hide the button */
      -webkit-appearance: none; /* turn off default browser styling */
    }
  }

  .date_picker_icon {
    position: absolute;
    right: -1px;
    top: 0;
    bottom: 0;
    padding: 0 8px;
    align-items: center;
    display: flex;
    border-radius: 4px;
    margin: 2px;
  }

  .date_picker_options_container {
    position: absolute;
    top: calc(100% + 7px);
    left: 0px;
    width: 100%;
    max-width: 382px;
    transition: all 225ms linear;
    border-radius: 2px;
    transform: scale(0);
    transform-origin: top left;
    z-index: 5;
  }

  .date_picker_open_options_container {
    transform: scale(1);
    min-width: 300px;
  }

  .icon_calendar {
    font-size: 18px;
    color: #000;
    width: 28px;
    height: 29px;
  }
  .check {
    margin: 40px;
  }
}
