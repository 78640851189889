.components_badge_edit_model_text {
  display: flex;
  flex-direction: column;
  width: 100%;

  .edit_text_title {
    font-size: 20px;
    font-weight: bold;
    color: #0c3d9c;
    margin-bottom: 12px;
    margin-top: 30px;
  }

  .edit_model_text_sliders_container {
    display: flex;
    flex-direction: column;
  }
  .text_margin {
    margin-bottom: 27px;
    margin-top: 0px;
  }
}
