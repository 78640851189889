.components_form_create_badge_features {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .features_form_select_container {
    display: flex;
    justify-content: space-between;
  }
  .features_form_title {
    color: var(--greyish-brown);
    font-size: 20px;
    font-weight: 300;
  }
  .features_form_select {
    width: calc(50% - 30px);
    margin-top: 33px;
  }
  .features_form_description {
    margin-top: 39px;
  }

  .features_form_checkbox {
    width: 15px;
    height: 15px;
  }
  .features_form_tags {
    margin-top: 38px;
    width: calc(50% - 30px);
  }
  .features_form_checkbox {
    margin-top: 63px;
  }

  .features_form_echelon_input {
    margin-top: 15px;
  }

  .features_form_echelon_inputs_container {
    margin-top: 50px;
    &:first-child {
      margin-top: 0;
    }
  }

  .margin_button_form {
    margin-top: 86px;
  }
}
