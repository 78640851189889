body {
  --cherry: #ed1e79;
  --pinkish-grey: #cccccc;
  --greyish-brown: #4d4d4d;
  --error: #d32f2f;
  --error-border: #f44336;
}

body {
  --input-height: 44px;
}
