$margin: 8px;
.components_inputs_tags_input {
  display: flex;
  flex-direction: column;
  width: 100%;
  .tags_input_text {
    margin-bottom: 23px;
    font-size: 16px;
    color: #434343;
  }
  .tags_input_container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    padding-left: 4px;
    padding-right: 4px;
    .tags_input_border {
      position: absolute;
      top: -1px;
      bottom: -1px;
      right: -1px;
      left: -1px;
      border: 1px solid var(--greyish-brown);
      border-radius: 4px;
      transition: border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, border-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      z-index: -1;
      background-color: #ddf7ff;
      border: 1px solid #e0e0e0;
    }

    &:hover .tags_input_border {
      border-color: #212121;
    }

    .tags_input_border_focused {
      border-color: #5b78c2 !important;
      border-width: 2px;
    }

    .tags_input_input {
      height: 30px;
      margin: $margin;
      padding: 0;
      flex: 1 1 0%;
      min-width: 25%;
      margin-left: 20px;
      input {
        border: none;
        font-size: 16px;
        width: 100%;
        height: 100%;
        background: transparent;
      }
      input::placeholder {
        color: var(--pinkish-grey);
        opacity: 1;
      }
    }

    .tags_input_tag {
      margin: 10px 20px;
      background-color: #5b78c2 !important;
      color: #fff !important;
      height: 28px !important;
      border-radius: 14px !important;
      font-size: 16px !important;
      text-transform: capitalize;
    }

    .tags_input_delete {
      color: #fff !important;
      width: 18px;
      height: 18px;
      margin-left: 12px !important;
      margin-right: 6px !important;
    }
    .tags_input_tag_label {
      margin-bottom: 2px;
    }
    .tags_input_border_error {
      border-color: #d42061 !important;
    }
  }
}
