.components_criteria_form {
  position: relative;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;

  .content_container {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .components_question_form {
    margin-top: 60px;
  }

  .first_row {
    justify-content: space-between;
  }

  .row_input {
    width: calc(50% - 30px);
  }

  .background_white {
    background-color: #fff;
  }

  .submit_button {
    margin-top: 6px;
    width: 300px;
  }
  .submit_button_option {
    font-size: 20px !important;
    color: #0c3d9c;
    font-weight: bold;
    margin-left: 30px;
  }
  .button_delete_option {
    font-size: 18px !important;
    margin-left: 30px;
  }
  .submit_button_question {
    margin-top: 6px;
    width: 250px;
  }
  .option_container {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
  }

  .submit_button_border {
    margin-top: 20px;
  }
  .error_text {
    position: relative;
  }
  .option_input {
    flex: 1 1 0;
  }
  .margin_top_8 {
    margin-top: 8px;
  }
  .submit_button_criteria {
    text-align: center;
    color: #5b78c2;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    align-self: flex-end;
    align-items: center;
    display: flex;
    line-height: 1;
  }
  .checked_icon {
    margin-right: 17px;
  }
  .title_criteria_form_container {
    display: flex;
    align-items: center;
  }
  .title_criteria_form {
    font-size: 30px !important;
    margin-left: 17px;
  }
  .height_input {
    height: 73px;
  }
  .required_className {
    color: #dd4774 !important;
  }
  .criteria_form_root {
    border: 1px solid #5b78c2;
    padding: 32px 58px;
    border-radius: 10px;
  }
  .question_content_container {
    padding-top: 37px;
  }
  .text_input_className {
    > div {
      height: 100%;
    }
  }
  .select_answer_container {
    margin-top: 17px;
  }
  .render_answer_container {
    margin-top: 14px;
  }
  .error_option {
    justify-content: center;
    height: 20px;
  }
  .title_question_container {
    display: flex;
    align-items: center;
    font-size: 26px;
  }
  .question_form_image {
    margin-right: 32px;
  }
  .submit_button_criteria_container {
    display: flex;
    justify-content: flex-end;
  }
  .margin_right_25{
    margin-right: 25px;
  }
  .select_image {
    margin-right: 24px;
  }
}
