.components_inputs_error_text {
  position: absolute;
  bottom: 2px;
  line-height: 15px;
  font-size: 16px;
  left: 0;
  white-space: nowrap;
  color: #d42061;
}

.components_inputs_input_with_error {
  position: relative;
  padding-bottom: 20px;
}
