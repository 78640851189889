.button_component {
  box-shadow: none !important;
  text-transform: none !important;
  position: relative;
  border-radius: 10px !important;
  padding: 0px 35px !important;
  .button_loader {
    position: absolute;
    right: 20px;
    color: #fff;
    top: 0;
    bottom: 0;
  }
}
.button_padding {
  padding-left: 50px !important ;
  padding-right: 50px !important ;
}
.button_max {
  padding-left: 68px !important ;
  padding-right: 68px !important ;
  width: 232px;
}
.button_radius {
  border-radius: 50px !important;
}

.submit_button_base {
  font-size: 20px !important;
  text-align: center !important;
  line-height: normal !important;
}

.submit_button_large {
  height: 70px !important;
}

.submit_button_medium {
  height: 44px !important;
}

.submit_button_small {
  height: 33px !important;
}
