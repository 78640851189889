.components_pickers_color_picker {
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 15px);
  width: 25vw;
  height: auto;
  left: 0;
  overflow: visible !important;
  z-index: 1;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none !important;
  &:after {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    left: 15px;
    bottom: 100%;
  }
  &:before {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid rgba(0, 0, 0, 0.2);
    left: 14px;
    bottom: 100%;
  }
  .badge_modal_colors_container {
    overflow: auto;
    display: flex;
    margin: 9px 4px;
    width: calc(100% - 15px);
  }

  .badge_modal_color_container {
    flex-direction: column;
    display: flex;
  }
  .badge_modal_color {
    height: 25px;
    width: 25px;
    border: solid 1px var(--pinkish-grey);
    border-radius: 50%;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    margin: 9px 4px;
  }
}
