.modal_block_container {
  background-color: #fff;
  width: 65%;
  height: 300px;
  padding: 1em;
}
.modal_block_content {
  display: flex;
  flex-direction: column;
}
.title_margin {
  margin-top: 25px;
  text-align: center;
}
.title_button {
  margin-top: 35px;
  display: flex;
  width: 75%;
  justify-content: center;
  justify-content: space-evenly;
}
.title_button_register {
  margin-top: 35px;
  display: flex;
  width: 100%;
  justify-content: center;
}
