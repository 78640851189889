.calendar_container {
  padding: 20px;
  max-width: 400px;
  position: relative;
  .header {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: space-between;
    color: #212121;
    .span {
      cursor: pointer;
      &:hover {
        color: grey;
      }
    }
  }
  .arrow {
    height: 24px;
    width: 24px;
    color: #212121;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      color: #f57c00;
    }
    &:disabled {
      color: #bdbdbd;
    }
  }
  .right_arrow {
    margin-right: 6px;
  }
  .row {
    display: flex;
    width: 100%;
    height: 32px;
    align-items: stretch;
  }
  .dayOfWeek {
    flex: 1 1 0%;
    color: #212121;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 500;
  }
  .day {
    font-size: 13px;
    flex: 1 1 0%;
    padding: 2px;
    display: flex;
  }
  .date {
    border-radius: 6px;
    font-size: 12px;
    width: 100%;
    height: 100%;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #616161;
    &:hover {
      background-color: #212121;
      color: #fff;
    }
    &:disabled {
      color: #bdbdbd;
      pointer-events: none;
    }
  }
  .today {
    color: #01579b;
  }
  .selected {
    background-color: #212121;
    color: #fff;
    pointer-events: none;
    cursor: pointer;
  }
}
.years_container {
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow-y: scroll;
    position: absolute;
    width: 90%;
    height: 75%;
    background: white;
    flex-wrap: wrap;
  }
  .year {
    height: 30px;
    width: 80px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    flex-direction: row;
    &:hover {
      background-color: #212121;
      color: #fff;
    }
  }
  

