.catergorie_logo_component {
  height: 34px;
  width: 34px;
  position: relative;
  .categorie_cercle {
    height: 34px;
    width: 34px;
    border: 2px solid #0c3d9c;
    border-radius: 50%;
    position: relative;
  }
  .label {
    position: relative;
    left: 32px;
    bottom: 50px;
    font-size: 20px;
    font-weight: 300;
    text-align: left;
    color: #4d4d4d;
  }
  .img_logo {
    z-index: 2;
    position: relative;
    bottom: 37px;
    right: -7px;
    width: 8px;
    height: 13px;
  }
  .categorie_logo_card {
    width: 12px;
    height: 12px;
  }
  .lettre {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    font-family: Rakkas;
    text-align: left;
    color: #0c3d9c;
    text-transform: initial;
  }
}
