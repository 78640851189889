.components_inputs_text_input {
  width: 100%;

  .text_input_input {
    width: 100%;
    border-radius: 5px;
    background-color: #ddf7ff;
    border: 1px solid #e0e0e0;
    fieldset {
      display: none;
    }
    > div {
      padding: 0 !important;
    }
    input,
    textarea {
      font-size: 20px;
      box-sizing: border-box;
      height: auto;
      color: #434343 !important;
      padding: 27px 32px !important;
      line-height: 147.5%;
    }
    input {
      height: var(--input-height) !important;
      padding: 0px !important;
      margin: 0px 27px;
      width: 90% !important;
    }
    input::placeholder,
    textarea::placeholder {
      color: #434343 !important;
      opacity: 1;
      font-size: 20px;
      font-family: Gangster Grotesk;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
  .components_inputs_text_input_with_error {
    border-color: #d42061 !important;
  }
}
