.components_form_create_badge_criteria {
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  padding: 0px 40px;
  .create_badge_criteria_dates_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 27px;
  }
  .create_badge_criteria_date_input {
    width: calc(50% - 21px);
  }
  .create_badge_generate_code {
    width: 60%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-self: flex-start;
  }
  .create_badge_criteria_checkbox {
    margin-top: 32px !important;
    align-self: flex-start;
  }
  .create_badge_criteria_checkbox_helper {
    color: #2d2a26;
    line-height: 1.33;
    font-size: 18px;
    margin-left: 3px;
  }

  .release_label {
    margin-top: 50px;
    margin-bottom: 26px;
    display: flex;
    font-size: 20px;
    align-items: center;
    color: #0c3d9c;
    align-self: flex-start;
  }

  .expected_criteria_container {
    border-radius: 4px;
    border: solid 1px #818181;
    background-color: rgba(171, 170, 168, 0.14);
    width: 100%;
  }

  .code_input_container {
    margin-left: 8px;
  }

  .generate_code_button {
    color: #fff !important;
    background-color: #0c3d9c;
    //enlever height
    height: 44px;
    margin-right: 10px;
    &:hover {
      color: #fff !important;
      background-color: #5b78c2;
    }
  }
  .generate_code_input {
    flex: 1;
  }
  .echelon_bottom {
    margin-bottom: 16px;
    margin-top: 71px;
    width: calc(50% - 80px);
  }
  .circle_cherry {
    background: var(--cherry);
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-right: 17px;
  }
  .margin_button_form {
    margin-top: 150px;
  }
  .title_criteria {
    font-size: 30px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 60px;
  }
  .label_date_picker {
    color: #0c3d9c;
    font-size: 20px;
    font-weight: bold;
  }
  .continue_button {
    width: 232px;
    padding: 0px !important;
    align-self: center;
    margin-top: 60px;
  }
  .title_form_image_container {
    display: flex;
    align-items: center;
  }
  .criteria_form_image {
    margin-right: 32px;
  }
  .add_container {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 90px;
    color: #0c3d9c;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    align-self: flex-start;
    margin-left: 62px;
  }
  .add_container_image {
    margin-right: 20px;
  }
  .create_modal_container {
    margin-top: 39;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text_description_modal {
    width: 581px;
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    margin: 0;
    margin-top: 18px;
  }
  .title_modal {
    font-weight: normal;
  }
  .modal_classNames {
    height: auto !important;
  }
  .link_submit_button {
    margin-top: 40px;
    margin-bottom: 70px;
  }
  .date_picker_className {
    width: 222px !important;
    margin-top: 17px;
  }
  .label_className {
    margin-bottom: 29px;
  }
}
